import React from 'react';
import TechStackImg from '../../assets/images/TechStacks.png';
import { ReactComponent as RemoteBricksAnimationLogo } from '../../assets/images/RemotebricksLoading/animation-logo.svg'

const TechStack = () => {
    return (
        <section className="section tech-stack-section">
            <div className="container tech-stack-container">
                <div className="section-heading text-center mb-5">
                    <h5 className='fw-reg mb-2'>Our</h5>
                    <h3 className='fw-sbo'>Tech Stack</h3>
                </div>

                <div className="tech-stacks">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tech-stack-description text-center mb-5">
                                <p className='fw-reg'>At Remote Bricks, we're expanding our tech stack to build more robust, scalable, and efficient solutions. On the front end, we leverage modern frameworks like React, Angular, and Vue.js, ensuring fast, responsive, and user-friendly interfaces. For the backend, we're deepening our use of Node.js, Python (Django/Flask), and Java (Spring Boot), supported by robust databases like PostgreSQL, MongoDB, and MySQL. Additionally, our QA processes are powered by cutting-edge automation tools such as Selenium, Cypress, and Jest, ensuring our software meets the highest standards of quality and performance across all platforms.</p>
                            </div>

                            <div className="tech-stack-thumb">
                                <RemoteBricksAnimationLogo className='animation-logo' />
                                <img src={TechStackImg} alt="techstack" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TechStack